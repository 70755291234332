import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";

const Navigation = () => {
  let location = useLocation();

  return (
    <Navbar fixed="top" expand="lg" className="header-custom">
      <Container>
        <Navbar.Brand as={Link} className="custom-logo" to="/">
          <p>
            BioInspire<span className="green">.</span>tech
          </p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="toggle_menue_icon">
            {" "}
            <AiOutlineMenu />{" "}
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink
              className={(navData) =>
                navData.isActive ||
                location.pathname.includes("What") ||
                location.pathname.includes("Why") ||
                location.pathname.includes("How")
                  ? "custom-link active"
                  : "custom-link"
              }
              to="/"
            >
              {" "}
              <span> What is BioInspire </span>{" "}
            </NavLink>

            <NavLink
              className={(navData) =>
                navData.isActive || location.pathname.includes("/search-result")
                  ? "custom-link active"
                  : "custom-link"
              }
              to="/search-page"
            >
              Search
            </NavLink>
            <NavLink
              className={(navData) =>
                navData.isActive ? "custom-link active" : "custom-link"
              }
              to="/about"
            >
              About us
            </NavLink>
            <NavLink
              className={(navData) =>
                navData.isActive ? "custom-link active" : "custom-link"
              }
              to="/support"
            >
              Support
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
