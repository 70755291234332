import React from "react";
import { FiChevronsRight } from "react-icons/fi";
const { detect } = require("detect-browser");

const Card = ({ data, index, handelActiveBoxStatus }) => {
  const browser = detect();

  // handle the case where we don't detect the browser
  if (browser) {
    // firefox
    console.log(browser.name);
    console.log(browser.version);
    console.log(browser.os);
  }

  return (
    <div className="col-11 p-3 ">
      <div
        className={
          data.isActive
            ? " card search_result_grid_card_wrapper activeBox"
            : "card search_result_grid_card_wrapper "
        }
        onClick={() => handelActiveBoxStatus(index)}
      >
        <div class=" d-flex justify-content-between">
          <div className="text_secondary text_bold">
            Book Title: {data?.BookTitle}
          </div>
          {/* <div className="text_primary text_bold">85% Match</div> */}
        </div>

        <div className="card-body text_secondary">
          {data.Text.length > 170
            ? `${data.Text.slice(0, 170)}....`
            : data.Text}
        </div>

        <div class=" text-muted d-flex justify-content-between ">
          <div className="text_secondary text_bold">
            Page Number : {data.PageNumbers}
          </div>
          <div className="text_secondary">
            {data.TopicName?.length > 25
              ? data.TopicName.slice(0, 25) + "..."
              : data.TopicName}{" "}
          </div>
          <div className="text_secondary text_bold cursor">
            {" "}
            Go Here
            <FiChevronsRight />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
