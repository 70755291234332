import React from "react";
import "./BoxList.css";
import { useNavigate } from "react-router-dom";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

const BoxList = () => {
  let navigate = useNavigate();

  const handelRedirectNavigation = (type) => {
    navigate(`/${type}`);
  };

  // onClick={() => handelRedirectNavigation("Books")}
  return (
    <div className="col-12 d-flex justify-content-center ">
      <div className="row">
        <div className="col-4">
          <div className="boxTitle boxTitle-1 right-before-after cursor">
            <Link to="test1" activeClass="active"
     
     spy={true}
     smooth={true}
     offset={-100}
     duration={200}>

            
        <strong onClick={() => handelRedirectNavigation("What")} className="text_primary"> What </strong>{" "} 
            </Link>
          </div>
        </div>

        <div className="col-4">
          <div className="boxTitle boxTitle-2 right-before-after ">
            <div className="left-before-curcle cursor">
              <strong onClick={() => handelRedirectNavigation("Why")} className="text_primary"> Why </strong>
            </div>
          </div>
        </div>
{/* 
        <div className="col-3">
          <div className="boxTitle boxTitle-3 right-before-after ">
            <div className="left-before-curcle cursor">
              <strong onClick={() => handelRedirectNavigation("Context")} className="text_primary"> How</strong>
            </div>
          </div>
        </div> */}

        <div className="col-4">
          <div className="boxTitle boxTitle-4 left-before-curcle">
            <div className="left-before-curcle cursor">
              <strong onClick={() => handelRedirectNavigation("How")} className="text_primary"> How</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxList;
