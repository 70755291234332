import React from "react";
import Card from "../../Components/Card";
import Heading from "../../Components/Common/Heading";
import prof1 from "../../Resource/images/prof1.jpg";
import prof2 from "../../Resource/images/prof2.jpg";
import prof3 from "../../Resource/images/prof3.jpeg";
import LayOut from "../../Layout";
import "./About.css";
const teams = [
  {
    id: 1,
    name: "Dr. Ravi Guttal",
    role: "Adjunct Professor",
    img: prof1,
    description:
      "Tincidunt consequat, consequat amet nunc arcu pellentesque. Faucibus volutpat convallis eget nulla fermentum pellentesque tempor, diam. Amet nunc auctor.",
  },
  {
    id: 2,
    name: "Aditya Deshpande",
    img: prof2,
    role: "Chief Researcher",
    description:
      "Tincidunt consequat, consequat amet nunc arcu pellentesque. Faucibus volutpat convallis eget nulla fermentum pellentesque tempor, diam. Amet nunc auctor.",
  },
  {
    id: 3,
    name: "Kishore Kulkarni",
    img: prof3,
    role: "Developer",
    description:
      "Tincidunt consequat, consequat amet nunc arcu pellentesque. Faucibus volutpat convallis eget nulla fermentum pellentesque tempor, diam. Amet nunc auctor.",
  },
  {
    id: 4,
    name: "Kiran Katarki",
    img: prof3,
    role: "Developer",
    description:
      "Tincidunt consequat, consequat amet nunc arcu pellentesque. Faucibus volutpat convallis eget nulla fermentum pellentesque tempor, diam. Amet nunc auctor.",
  },
];

const Team = () => {
  return (
    <div className="wrapper-background-onScroll">
      <div className="TeamContainer ">
        <LayOut>
          <div className="container mt-5">
            <Heading title="Who are we?" />

            <div className="background p-4">
              {/* <ImageComponent /> */}
              <div class="row">
                {teams?.map((team) => (
                  <Card team={team} key={team.id} />
                ))}
              </div>
            </div>
          </div>
        </LayOut>
      </div>
    </div>
  );
};

export default Team;
