import React from "react";
import { Col } from "react-bootstrap";
import './Card.css'
const Card = ({team}) => {
    const {img,name,description,role} = team
  return (
    <div className="col-lg-6">
      <div className="card  border-0 bg-transparent">
        <div className="row g-4  g-xl-0">
          <div className="col-sm-3">
            <img src={img} className="card-img mx-auto d-block" alt="..." />
          </div>
          <div className="col-sm-9">
            <div className="card-body">
              <h5 className="card-title">{name}</h5>
              <p className="card-text">{role}</p>
              <p className="card-text">
                 {description}
              </p>
            </div>
          </div>
        </div>
      </div>
   </div>
  );
};

export default Card;
