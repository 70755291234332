import React from "react";
import Heading from "../../Components/Common/Heading";
import HomeSearch from "../../Components/Common/SearchBar";
import BoxCard from "../../Components/Home/BoxCard";
import BoxList from "../../Components/Home/BoxList/BoxList";

const FirstView = () => {
  const boxCardContent = [
    {
      title: "Learn",
      details: `By scouting and feeding relevant source material to our engine we are able to understand the context being explained in a book, article or a blog.
      We further plan to expand the capabilities to the internet.




      
                `,
    },
    {
      title: "Categorize",
      details: `Once the articles are decoded and extracted, the engine created by us is able to categorize the information and arrange it according to the decoded context of the sentence or paragraph.
                 `,
    },
    {
      title: "Inspire",
      details: `By allowing the engine to breakdown the context we are enabling the data to be searched for relevant biological information with natural language prompts.
      Nature Inspired Research! 
                 `,
    },
  ];

  return (
    <>
      <div id="Home_page">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-4 offset-lg-8 mt-5">
              <HomeSearch />
            </div>
          </div>

          <div>
            <Heading title="Learning From Nature. Executed" />
            <div className="row mt-2">
              <BoxList />
            </div>
            <div className="row mt-4">
              {boxCardContent.map((x, index) => (
                <BoxCard key={x} title={x.title} details={x.details} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstView;
