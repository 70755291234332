import React from "react";
import { Form } from "react-bootstrap";
import "./ContactForm.css";
const ContactForm = () => {
  return (
    <div className="contract-form-container">
      <Form className="">
        <Form.Group className="mb-3 py-1" controlId="exampleForm.ControlInput1">
          <Form.Control className="py-3" type="text" placeholder="Name" />
        </Form.Group>
        <Form.Group className="mb-3 py-1" controlId="exampleForm.ControlInput1">
          <Form.Control className="py-3 " type="email" placeholder="Email" />
        </Form.Group>
        <Form.Group
          className="mb-0 py-1"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            className="py-3 "
            as="textarea"
            rows={5}
            placeholder="Description"
          />
        </Form.Group>
      </Form>
      <button className="btn-custom">Get in Touch!</button>
    </div>
  );
};

export default ContactForm;
