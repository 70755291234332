import { Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchLeftContent from "../../Components/SearchResult/SearchLeftContent";
import SearchRightContent from "../../Components/SearchResult/SearchRightContent";
import LayOut from "../../Layout";
import "./SearchResult.css";

const SearchResult = () => {
  const [searchData, setSearchData] = useState("");
  const perams = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const [searchResult, setSearchResult] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totaPage, setTotalPage] = useState(1);

  const backend_search_url = process.env.REACT_APP_BACKEND_SEARCH;
  useEffect(() => {
    if (location.state?.searchQuery) {
      setSearchData(location.state?.searchQuery);

      fetchData();
    } else {
      navigate("/search-page");
    }
  }, []);

  const handelSubmitSearch = (e) => {
    e.preventDefault();
    setSearchError(null);
    fetchData();
  };

  function fetchData() {
    setSearchLoading(true);
    const data = {
      input_sentence: searchData || location.state?.searchQuery,
      page_number: pageNumber,
    };

    axios
      .post(backend_search_url, data)

      .then((res) => {
        setSearchLoading(false);
        setSearchResult(res.data);
        setSearchError(null);
      })
      .catch((error) => {
        setSearchLoading(false);
        setSearchError("no result found!");
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <LayOut>
      <div id="search_result_page" className="wrapper-background-onScroll">
        <div className="container pt-5  mt-5">
          <div className="row  search_result_left_wrapper">
            <div className="col-md-7 bg-white border-none mr-3" id="cover">
              <form onSubmit={handelSubmitSearch}>
                <div class="tb">
                  <div class="td">
                    <input
                      defaultValue={searchData}
                      onChange={(e) => setSearchData(e.target.value)}
                      type="text"
                      placeholder="search"
                      required
                    />
                  </div>
                  <div class="td" id="s-cover">
                    <button type="submit">
                      <AiOutlineSearch className="searchIcon" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-7 order-md-1 order-2">
              <div className="search-left-content p-3">
                <SearchLeftContent
                  handelSubmitSearch={handelSubmitSearch}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  searchResult={searchResult}
                  searchLoading={searchLoading}
                  searchError={searchError}
                  setPageNumber={setPageNumber}
                  setSearchResult={setSearchResult}
                />
              </div>
            </div>
            <div className="col-md-5 order-md-2 order-1">
              {searchLoading ? (
                <>
                  {" "}
                  <div className="row mt-2 p-2">
                    <div className="col-md-11 my-2">
                      <Skeleton active />
                    </div>
                    <div className="col-md-11 my-2">
                      <Skeleton active />
                    </div>
                    <div className="col-md-11 my-2">
                      <Skeleton active />
                    </div>
                  </div>{" "}
                </>
              ) : (
                !searchError && (
                  <div className="search-right-content">
                    <SearchRightContent
                      searchResult={searchResult}
                      searchLoading={searchLoading}
                      searchError={searchError}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default SearchResult;
