import Heading from "../../Components/Common/Heading";
import Process from "../../Components/Home/Process";
import "./bubleDetails.css";
import { useParams } from "react-router-dom";
import LayOut from "../../Layout";

const BubleDetails = () => {
  let { type } = useParams();

  console.log(type, "parems");

  return (
    <div className="wrapper-background-onScroll">
      <LayOut>
        <div className="buble-details py-5">
        <div className="container"> 
          <div className="row mt-4">
            <div className="col-md-12  ">
              <Heading title="What is the process we follow?" />
            </div>
          </div>
          <div className="row">
            <div id="process_content_wrapper" className="col-md-10 offset-md-1 mx-auto py-3">
              <Process />
            </div>
            </div>
            </div> 
        </div>
      </LayOut>
    </div>
  );
};

export default BubleDetails;
