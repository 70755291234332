import React from 'react';
import Fade from 'react-reveal/Fade';
import Heading from '../../Components/Common/Heading';
import HomeSearch from '../../Components/Common/SearchBar';
import LayOut from '../../Layout';
import "./search.css";
const Search = () => {


    return (
        <div   id="searchFullWidth" className="wrapper-background-onScroll">
            <LayOut>
                <div className="container pt-5 mt-5">
                    <div className='col-md-8 offset-md-2  mx-auto mt-5'>
                    
                    <Fade top >
                    <div className="pt-5">
                      <Heading title="Let’s get inspired!" />
                    </div>
                  </Fade>
                  </div>                
                    <div className='col-md-8 offset-md-2  mx-auto '>
                    
                    <Fade top>
                    <div className="py-4">
                      <HomeSearch />
                    </div>
                  </Fade>
                  </div>                
                </div>

            
            </LayOut>
            
        </div>
    );
};

export default Search;