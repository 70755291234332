import React from "react";
import "./ContactContentLeft.css";
const ContactConentLeft = () => {
  return (
    <div>
      <h4 className="contact-left-header py-3">
        We will be glad to hear from you!
      </h4>
      <p className="contact-left-paragraph py-2 text_primary">
        A Product always improves with contructive feedback from users like
        yourself. Chime in with your thoughts on what we could do better.
        <div className="contact-info py-3 mt-3 ">
          {/* <p>
            <strong> Aditya Deshpande </strong>
          </p>
          <span>aditya@kletech.ac.in </span> */}
        </div>
        <div className="contact-info py-3 ">
          {/* <p><strong>  Kishore Kulkarni   </strong></p>  */}
          <span>support@bioinspire.tech</span>
        </div>
      </p>
    </div>
  );
};

export default ContactConentLeft;
