import { baseUrl } from './../../App';
import React,{useState, useEffect} from "react";
import axios from "axios"

import "./Home.css";


import LayOut from "../../Layout";
import placeHolder from "../../Resource/images/placeholderimage.webp"



import FirstView from "./FirstView";
import SecondView from "./SecondView";
import useSWR from 'swr'

const Home = ({bgImage}) => {
 

 
  return (
    
    <div  className="wrapper-background-onScroll">
      
        <LayOut >
       
        
       <FirstView />
     {/* <SecondView/> */}
   
     </LayOut>
      
 
  
  
   
      </div>
    
  );
};

export default Home;


