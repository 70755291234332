import { useState, useEffect } from "react";
import "./progress.css";
import Content from "./Content";
import { useLocation } from "react-router-dom";
const Process = () => {
  const location = useLocation();
  const [activeBox, setActiveBox] = useState({
    box1: true,
    box2: false,
    box3: false,

    details: "box1",
  });

  useEffect(() => {
    if (location.pathname.includes("What")) {
      setActiveBox({
        box1: true,
        box2: false,
        box3: false,

        details: "box1",
      });
    }
    if (location.pathname.includes("Why")) {
      setActiveBox({
        ...activeBox,
        box1: true,
        box2: true,
        box3: false,

        details: "box2",
      });
    }
    if (location.pathname.includes("How")) {
      setActiveBox({
        ...activeBox,
        box1: true,
        box2: true,
        box3: true,

        details: "box3",
      });
    }
  }, [location.pathname]);

  return (
    <>
      <div id="test1" className="row d-flex mx-auto px-5">
        <div
          onClick={() =>
            setActiveBox({
              ...activeBox,
              box1: true,
              box2: false,
              box3: false,

              details: "box1",
            })
          }
          className={
            activeBox?.box1
              ? "roundShape dot  roundShape_active_bg ml-auto "
              : "roundShape dot ml-auto text_primary"
          }
        >
          1
        </div>
        <div className="HrWrapper rightArrow "></div>

        <div
          onClick={() =>
            setActiveBox({
              ...activeBox,
              box1: true,
              box2: true,
              box3: false,

              details: "box2",
            })
          }
          className={
            activeBox?.box2
              ? "roundShape dot  roundShape_active_bg "
              : "roundShape dot text_primary"
          }
        >
          2
        </div>
        <div className="HrWrapper rightArrow "></div>

        <div
          onClick={() =>
            setActiveBox({
              ...activeBox,
              box1: true,
              box2: true,
              box3: true,

              details: "box3",
            })
          }
          className={
            activeBox?.box3
              ? "roundShape roundShape_active_bg "
              : "roundShape text_primary"
          }
        >
          3
        </div>
      </div>

      <div className="row ">
        <Content activeBox={activeBox.details} />
      </div>
    </>
  );
};

export default Process;
