import "antd/dist/antd.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import BubleDetails from "./Pages/BubleDetails";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Search from "./Pages/Search";
import SearchResult from "./Pages/SearchResult";
export const baseUrl = process.env.REACT_APP_BACKEND_URL;
function App() {
  // const fetcher = (url) => axios.get(url).then((res) => res.data);
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  // const { data, error } = useSWR(`${baseUrl}/services/bg`, fetcher);

  useEffect(() => {
    setLoader(true);

    axios
      .get(`${baseUrl}/services/bg`)
      .then((res) => {
        const min = 1;
        const max = res.data.length - 1;
        const index = Math.floor(Math.random() * (max - min + 1)) + min;

        document.documentElement.style.setProperty(
          "--bg-image",
          `url(${res.data[index].media})`
        );

        const img = new Image();
        img.src = res.data[index].media;
        img.onload = () => setLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoader(false);
      });
  }, []);

  
  if (loader) {
    return (
      <div className="loader-container d-flex align-items-center justify-content-center">
        <div class="loader">
          <div class="outer"></div>
          <div class="middle"></div>
          <div class="inner"></div>
        </div>
      </div>
    );
  } else {
    return (
      <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/:type" element={<BubleDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Contact />} />
          <Route path="/search-page" element={<Search />} />
          <Route path="/search-result" element={<SearchResult />} />
        </Routes>
      </AnimatePresence>
    );
  }
}
export default App;
