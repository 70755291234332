import React from 'react';
import "./boxCard.css"

const BoxCard = ({title, details}) => {
    return (
        <>
              <div  className="col-lg-4  box_card_home_page mb-4">
                <div  className="content-container card">
                  <div class="card-body">
                        <h5 class="card-title text_primary text-center"> { title}</h5>
                    <p className='text_primary'>
                    {details}
                    </p>
                  </div>
                </div>
           
          </div>
            
        </>
    );
};

export default BoxCard;