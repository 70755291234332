import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

const HomeSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchData, setSearchData] = useState("");

  console.log(location, "navigate");
  const redirectSearchPage = () => {
    if (location.pathname === "/") {
      navigate("/search-page");
    }
  };
  const handelSubmitSearch = (e) => {
    e.preventDefault();
    if (searchData === "") {
      alert("please enter some keyword");
    } else {
      navigate(`/search-result`, { state: { searchQuery: searchData } });
    }
  };
  return (
    <div
      className={
        location.pathname === "/"
          ? "border-none bg-white"
          : "border-none bg-white custom_shadow"
      }
      id="cover"
    >
      <form onSubmit={handelSubmitSearch}>
        <div class="tb">
          <div class="td" onClick={redirectSearchPage}>
            <input
              onChange={(e) => setSearchData(e.target.value)}
              type="text"
              placeholder="search"
              required
            />
          </div>
          <div class="td" id="s-cover">
            <button type="submit">
              <AiOutlineSearch className="searchIcon" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HomeSearch;
