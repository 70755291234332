export  const box1={
    title: "What is Biomimicry?",
    description:[{
         
          para1:`Biomimicry can be defined as the field of study that dissects the underlying principles behind a particular function, structure or process in nature and helps provide inspiration for applying it to artificial systems. Every field of study, especially engineering has its own set of design philosophies or methodologies which have come to the aid of designers. It becomes increasingly difficult for an engineer as various domains start to merge for a particular application. 

        `},
    
           {  para1:`  Biomimetic design similarly poses a challenge for engineers as it requires a thorough understanding of biology and nature in all its vastness and variety. Multiple organisms can conduct a particular function in a myriad of ways and to choose one that best suits an engineering application can be a time-consuming process, even with the help of a biologist. `}
    ]
    
}

export const box2={
     title: "Why do we need Biomimicry?",
     description:[{
          
           para1:`Biologically inspired design is the way forward to improving the efficiency of artificial systems. In looking to nature for solutions, one can see that evolution is the key to the development of any form or function. This raises a vital question, what was the deciding factor for these developments? The answer lies in the interaction of the organisms with elements of nature: earth, water and air. 

          `},
     
            {  para1:` Furthermore, the physical laws which are common to all organisms dictated how the interaction took place. Equipped with this theory, the authors present a novel biomimetic approach to solve design engineering problems. The objective is to use the elements and physical laws in deriving analogies from nature to develop efficient and sustainable artificial technology.`},
    ] ,
 }

 export const box3={
     title: "Biomimetic idea generation using NLP",
     description:[{
          
           para1:`It is an established fact that the language of the biologist and engineers is vastly different. This creates a hindrance for an engineer to extract meaningful biological analogies and apply it in artificial systems. This paper summarises a novel method of keyword generation as an input to natural language processing to aid the design engineer in deriving inspiration and idea generation. 

          `},
     
            {  para1:` This process utilises an engineer’s familiarity with physical laws for identifying keywords and completely eliminates the need to manually search and link the biological database in terms of functions. The strategies employed to facilitate the search and filter the biological description has been detailed.`},
    ] ,
 }

// 
