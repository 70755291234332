import React from "react";

const SearchRightContent = ({ searchResult, searchLoading, searchError }) => {
  const tags = [
    "tag1",
    "comments",
    "parsers",
    "all in one",
    "Best Parts",
    "likely",
    "Beutiful",
    "awsom",
  ];

  return (
    <>
      {searchResult?.summary?.length && !searchError && (
        <div className="row mt-2 p-2">
          <div className="col-md-11  offset-md-1">
            <h4 className="text_bold text_secondary mb-2">Short Summary : </h4>
            <p className="text_secondary summary-details">
              {searchResult?.summary}
            </p>
          </div>
        </div>
      )}

      <div className="row ">
        <div className="col-md-11  offset-md-1">
          <h4 className="text_bold text_secondary"> Select Relevant Tags : </h4>{" "}
        </div>

        <div className="col-11 offset-md-1 d-flex  flex-wrap">
          {tags.map((x, index) => {
            return (
              <div key={index}>
                <div className="tagContainer d-flex justify-content-center align-items-center">
                  <strong className="text_secondary">{x} </strong>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SearchRightContent;
