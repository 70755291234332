import Pagination from "@mui/material/Pagination";
import { Skeleton } from "antd";
import React from "react";
import Card from "./Card";

const SearchLeftContent = ({
  setPageNumber,
  searchLoading,
  setSearchData,
  searchData,
  handelSubmitSearch,
  searchResult,
  searchError,
  setSearchResult,
}) => {
  let pageNumber = parseInt(
    Math.floor(searchResult?.totalResults / searchResult?.resultsPerPage)
  );

  if (pageNumber === "NaN") {
    pageNumber = 1;
  }
  const handlePageChange = (e, value) => {
    setPageNumber(value);
  };

  const handelActiveBoxStatus = (activeIndex) => {
    const updateActive = searchResult?.results?.map((x, index) => {
      if (index === activeIndex) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
      return x;
    });
    setSearchResult({ ...searchResult, results: [...updateActive] });
  };

  return (
    <>
      {searchLoading && (
        <div className="row mt-2 p-2">
          <div className="col-md-11 my-2">
            <Skeleton active />
          </div>
          <div className="col-md-11 my-2">
            <Skeleton active />
          </div>
          <div className="col-md-11 my-2">
            <Skeleton active />
          </div>
        </div>
      )}

      {searchResult?.results?.length === 0 || searchError ? (
        <div className="row mt-5 pt-5">
          <div className="col-md-11">
            <div class="alert alert-danger" role="alert">
              No data Found!!
            </div>
          </div>
        </div>
      ) : (
        !searchLoading &&
        searchResult?.results?.map((x, index) => {
          return (
            <div key={index} className="row mt-2">
              <Card
                data={x}
                index={index}
                handelActiveBoxStatus={handelActiveBoxStatus}
              />
            </div>
          );
        })
      )}

      {searchResult?.results?.length > 0 && !searchError && (
        <div className="row   my-4">
          <div className="pagination_container col-md-11      px-1   d-flex justify-content-center">
            <Pagination
              color="primary"
              onChange={handlePageChange}
              size="large"
              count={pageNumber}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchLeftContent;
