import React from "react";
import ContactForm from "../../Components/AboutForm";
import ContactConentLeft from "../../Components/ContactConentLeft";
import Heading from "../../Components/Common/Heading";
import LayOut from "../../Layout";
import "./Contact.css";
const Contact = () => {
  return (

    <div className="wrapper-background-onScroll">
        <div className="Contactcontainer">
        <LayOut>
          <div className="container mt-5 "> 

          <Heading title="Have some suggestions?" />
          <div className="">
          <div className="background mt-3">
            <div class="row row-cols-1 row-cols-md-2 ">
              <div className="col">
                <ContactConentLeft />
              </div>
              <div className="col">
                <ContactForm />
              </div>
            </div>
          </div>
          </div>
          </div>
      
    </LayOut>
     </div>
    </div>
  );
};

export default Contact;
