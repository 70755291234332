import React,{useState,useEffect} from 'react';
import { box1, box2, box3 } from './data';

const Content = ({activeBox}) => {
   
    const [activeContent, setActiveContent] = useState(box2)


   
 
    useEffect(() => {
       
        if(activeBox==="box1"){

            setActiveContent(box1)
        }
        
        else if (activeBox === "box2") {

            setActiveContent(box2)
        }
        else if(activeBox==="box3"){

            setActiveContent(box3)
        }
       
        

     
},[activeBox])
console.log()

    return (
        <div className='card  card-content-wrapper'>
            <div className='card-title'>

                <h3 style={{color:"white"}}> { activeContent.title}</h3>
            </div>

            <div className='card-body'> 

            {
                activeContent.description.map((x,inx)=>{

                     return <p  key={inx}>{x.para1}  </p>
                })
            }
               
            </div>
            
        </div>
    );
};

export default Content;