import React from 'react';
import "./heading.css"

const Heading = ({title}) => {
    return (
        <>
            <h1 className="text-center heading">{ title}</h1>
        </>
    );
};

export default Heading;