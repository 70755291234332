import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import PageAnimation from "../Helper/PageAnimation.jsx"

const LayOut = ({  children }) => {
  return (
    <>
     <Header  />
      <PageAnimation>  
     
      {children}
  
      </PageAnimation> 
    

      {/* <Footer /> */}
    </>
  );
};

export default LayOut;
